//com.southvanmed/src/components/Payment/payment.js
import React, { Fragment } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {connect} from 'react-redux';
import {
  Elements,
  ElementsConsumer,
  CardElement,
} from "@stripe/react-stripe-js";
import { Button, Container } from "react-bootstrap";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// components

import config from '../../config';
// const stripePromise = loadStripe(config.stripePublicKey);
// import ReactDOM from "react-dom";

// import { setAlert } from '../../actions/alert';

import { setAlert } from "../../services/sweetAlert";

import {setToLoading,setToLoaded} from '../../actions/loader';
import { sickNoteService } from "../../services/sicknote.service";

import { history } from '../../_helpers'
import CommonPageHeader from "../CommonPageHeader/CommonPageHeader";


const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
  //     iconColor: "#c4f0ff",
  //     color: "#fff",
  //     fontWeight: 500,
  //     fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
  //     fontSmoothing: "antialiased",
  //     ":-webkit-autofill": {
  //       color: "#fce883"
  //     },
  //     "::placeholder": {
  //       color: "#87bbfd"
  //     }
  //   },
  //   invalid: {
  //     iconColor: "#ffc7ee",
  //     color: "#ffc7ee"
    }
  }
};

const mapDispatchToProps = {
  setToLoaded,
  setToLoading
}
const mapStateToProps = state => ({
  sickNote: state.sickNote,
  user: state.auth.user,
})
class CheckoutForm extends React.Component {
  constructor() {
    super();
    this.state = {
      isDialog: false
    }
  }
  handleServerResponse = async (response) => {
    // console.log("Server response:", response);
    
    if (response.data.success) {
      this.props.setToLoaded();
      
      const { sickNote } = this.props;
      const formType = sickNote.data?.formType || 'sick-note';
      const formId = sickNote.data?.formId || response.data.extras?.formId;
      const accessToken = sickNote.data?.accessToken || response.data.extras?.accessToken;
      
      // console.log("Form type in handleServerResponse:", formType);
  
      // Store form ID in localStorage for recovery after payment
      if (formType === 'school-work-form' && formId) {
        localStorage.setItem('schoolWorkFormId', formId);
      }
  
      // For school-work forms, redirect to the file upload page instead of calling Oscar API directly
      if (formType === 'school-work-form' && formId) {
        // console.log("Redirecting to file upload page for form:", formId);
        
      // If we have an accessToken, use the token-based URL
      if (accessToken) {
        // console.log("Redirecting to token-based upload page:", accessToken);
        history.push(`/school-work-form/upload-token/${encodeURIComponent(accessToken)}`);
      } else {
        // Fallback to ID-based URL
        // console.log("Redirecting to ID-based upload page for form:", formId);
        history.push(`/school-work-form/upload/${formId}`);
      }
      return;
      }
      
      // Original sick note flow - unchanged
      const successPath = '/sick-note/payment/success';
      // console.log("Redirecting to success path:", successPath);
      history.push(successPath);
      return;
    }
  
    if (response.data.requires_action) {
      // Use Stripe.js to handle required card action
      let intentConfirm = await this.props.stripe.handleCardAction(
        response.data.payment_intent_client_secret
      );
      if (!intentConfirm || !intentConfirm.paymentIntent) {
        
        setAlert({
          message:'Card validation error',
          icon: 'error'
        })
        return;
      }
  
      response = await sickNoteService.charge({
        payment_intent_id: intentConfirm.paymentIntent.id,
        sickNote: this.props.sickNote
      });
  
      return await this.handleServerResponse(response);
    }
    
    this.props.setToLoaded();
    console.log("Error response:", response);
    setAlert({
      message:response.data.error || 'Card Failed',
      icon: 'error'
    })
    return;
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    
    const { stripe, elements } = this.props;
    const { sickNote } = this.props;
    
    // Make sure we're sending the proper data structure to the server
    const paymentData = {
      ...sickNote.data,
      formId: sickNote.data?.formId, // Ensure formId is included
      formType: sickNote.data?.formType || 'sick-note'
    };
    
    // console.log("Submitting payment with data:", paymentData);
  
    if (elements == null) {
      return;
    }
    
    this.props.setToLoading();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    
    if (error) {
      this.props.setToLoaded();
      console.log("Stripe error:", error);
      setAlert({
        message: error.message,
        icon: 'error'
      });
      return;
    }
      
    // console.log("Payment method created:", paymentMethod);
    let response = await sickNoteService.charge({
      payment_method_id: paymentMethod.id,
      sickNote: paymentData // Send the properly structured data
    });
    
    await this.handleServerResponse(response);
  };

  handleDialogClose = () => {
    this.setState({ isDialog: false})
    history.push('/dashboard');
  }

  handleGoBack = () => {
    const { sickNote } = this.props;
    const formType = sickNote.data?.formType || 'sick-note';
    
    // console.log("Going back from form type:", formType);
    
    // Navigate back based on form type
    const backPath = formType === 'school-work-form' 
        ? '/school-work-form' 
        : '/sick-note';
        
    // console.log("Navigating back to:", backPath);
    history.push(backPath);
  }

  render() {
    const { stripe, user, sickNote } = this.props;
    const formType = sickNote.data?.formType || 'sick-note';
    
    // console.log("Rendering CheckoutForm with formType:", formType);
    // console.log("Current sickNote data:", sickNote);
    
    return (
      <Fragment>
        <Dialog open={this.state.isDialog}>
          <DialogTitle>
          {formType === 'school-work-form' ? 'Request a Form' : 'Sick Note'} request submitted successfully
          </DialogTitle>
          <DialogContent>
            Doctor Budai will get back to you within 24 hours through email. The email we have on file is {user.email}.
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <div className="checkout-form mt-2">
      
            <form onSubmit={this.handleSubmit}>
              <div className="checkout-form__card">
                <CardElement />
              </div>
              <div className="checkout-form__buttons mt-1">
                <button
                  className="checkout-form__buttons__button primary_btn full-width ml-0"
                  type="submit"
                  disabled={!stripe}
                >
                  Pay now
                </button>
                <button
                  className="mt-1 checkout-form__buttons__button primary_btn bg-secondary full-width ml-0"
                  type="button"
                  onClick={this.handleGoBack}
                >
                  Go back
                </button>
              </div>
            </form>
          
        </div>
      </Fragment>
    );
  }
}
let CheckoutFormConnect = connect(mapStateToProps, mapDispatchToProps) (CheckoutForm)

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutFormConnect stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

const stripePromise = loadStripe(config.stripePublicKey);

class Payment extends React.Component {
  render() {
    // if user does not have to pay
    // go back to dashboard
    const { sickNote } = this.props;
    // console.log('sicknote field', sickNote)
    const formType = sickNote.data?.formType || 'sick-note';
    
    // console.log("Rendering Payment component with formType:", formType);
    // console.log("SickNote data in Payment component:", sickNote.data);
    
    // Set title based on form type
    const pageTitle = formType === 'school-work-form' 
        ?  `${sickNote.data.timeOffIsFor.toUpperCase()} Form Payment` 
        : "Sick Note Payment";
    const pageSubtitle = formType === 'school-work-form' 
        ? `${sickNote.data.timeOffIsFor}`
        : "sick-note/payment";
    
    // Set amount based on form type and other conditions
    let amount = 50; // Default for sick note
    
    if (formType === 'school-work-form') {
      // Use the calculated price from form data if available
      if (sickNote.data?.timeOffIsFor === 'disability') {
        amount = 750; // Flat fee for disability forms
      } else {
        // Use the calculated price from form data if available
        if (sickNote.data?.calculatedPrice) {
          amount = sickNote.data.calculatedPrice;
        } else {
          // Fallback to dynamic calculation if formData has pageCount
          const pageCount = sickNote.data?.pageCount || 1;
          const firstPagePrice = 250;
          const additionalPagePrice = 75;
          amount = firstPagePrice + ((pageCount - 1) * additionalPagePrice);
        }
      }
    } else if (sickNote.isPrivate) {
      amount = 100;
    }
    
    // console.log("Payment amount:", amount);
    // console.log('page amount', sickNote.data?.pageCount)
    
    // Get page count from form data for display
    const pageCount = sickNote.data?.pageCount || 1;
    
    return (
      <>
      <CommonPageHeader title={pageTitle} subtitle={pageSubtitle}/>
          <div className="payment">
              <Container>
                  <p className="mb-2">Please pay the following fee in order to obtain your {formType === 'school-work-form' ? 'request a form' : 'sick note'}:</p>
                  <span className="payment__fee badge badge-pill badge-success">Fee: ${amount}</span>
                  <span className="mb-2 ml-3">No refunds</span>
                  
                  {formType === 'school-work-form' && sickNote.data?.timeOffIsFor !== 'disability' && pageCount > 1 && (
                    <div className="mt-2 mb-2 p-3 border rounded bg-light">
                      <p className="mb-0"><strong>Price Breakdown:</strong></p>
                      <small>
                        First page: $250<br />
                        {pageCount > 1 && `${pageCount-1} additional page(s): $${(pageCount-1) * 75}`}
                      </small>
                    </div>
                  )}

                  {formType === 'school-work-form' && sickNote.data?.timeOffIsFor === 'disability' && (
                    <div className="mt-2 mb-2 p-3 border rounded bg-light">
                      <p className="mb-0"><strong>Disability Application Fee:</strong></p>
                      <small>
                        Flat fee: $750
                      </small>
                    </div>
                  )}
                  
                  <Elements stripe={stripePromise}>
                      <InjectedCheckoutForm />
                  </Elements>
              </Container>
          </div>
      </>
    );
  }
}
let temp = connect(mapStateToProps, mapDispatchToProps) (Payment)
export { temp as Payment };